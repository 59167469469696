import { Link, Outlet, useLocation, useNavigate } from '@remix-run/react'
import { Badge } from '~/components/ui/badge.tsx'
import { Icon } from '~/components/ui/icons/icon.tsx'
import { ScrollArea } from '~/components/ui/scroll-area.tsx'
import { Separator } from '~/components/ui/separator.tsx'
import { StarsRating } from '~/components/ui/stars.tsx'
import { getUserImgSrc } from '~/utils/misc.tsx'
import {
  type Category,
  type Review,
  type Service,
  type User,
  type UserImage,
} from '@prisma/client'
import { Price } from '../price.tsx'
import { Button } from '../ui/inputs/button/Button.tsx'
import { getServicesStar } from '~/features/reviews/getStarsAndReviewDataForProfesional.tsx'
import { type ServiceWithSessionAndReview } from '~/features/home/professional-profile.tsx'

type Props = {
  service: {
    name: Service['name']
    slug: Service['slug']
    price: Service['price']
    isVideoCall: Service['isVideoCall']
    description: Service['description']
    duration: Service['duration']
    serviceBooked: {
      review?: {
        stars: Review['stars']
      }
    }[]
    category: {
      id: Category['id']
      name: Category['name']
    }
    professional: {
      nickname: User['nickname']
      slug: User['slug']
      avatarImage: UserImage
      services: ServiceWithSessionAndReview[]
    }
  }
  currency: {
    value: number
    name: string
  }
}

export const HomeProfessionalSessionCard = ({ service, currency }: Props) => {
  const location = useLocation()
  const navigate = useNavigate()
  //Split the query by filter first so routing will not be affected
  const query = `?from=${location.pathname
    .split('?filter')[0]
    .replace('/', '')}${
    location.search.length > 0 ? `&${location.search.replace('?', '')}` : ''
  }`

  const stars = getServicesStar(service.professional.services)

  return (
    <>
      <div className="relative flex w-full max-w-full items-center gap-2 rounded-lg border border-input bg-white p-4 px-2 sm:gap-4 sm:px-4">
        <div className="relative flex w-full max-w-[65%] flex-grow flex-col items-center gap-4 border-input bg-white p-4 sm:max-w-[75%]">
          <div className="flex w-full items-start justify-between space-x-4 xs:space-x-1">
            <div className="flex flex-grow flex-wrap gap-4">
              <Badge
                className="bg-secondary-200 text-secondary"
                key={service.category.id}
                variant={'gray'}
              >
                {service.category.name}
              </Badge>
            </div>
            <Link to={`/${service.professional.slug}${query}&stars`}>
              <StarsRating value={stars} />
            </Link>
          </div>
          <div className="w-full">
            <p className="text-left text-h5">{service.name}</p>
          </div>
          <ScrollArea className="h-[100px]">
            <p className="text-foreground-muted">{service.description}</p>
          </ScrollArea>
        </div>

        <Separator className="h-full" orientation="vertical" />
        <div className="flex w-[100px] flex-grow flex-col items-center justify-start gap-4">
          <Link to={`/${service.professional.slug}${query}`}>
            <img
              className="mx-auto h-[45px] w-[45px] rounded-full object-cover sm:h-[80px] sm:w-[80px]"
              alt={String(service.professional.nickname)}
              src={getUserImgSrc(service.professional.avatarImage?.id)}
            />
          </Link>

          <p className="max-w-full text-wrap text-center text-h6 xs:max-w-[85%] xs:text-[14px]">
            con {service.professional.nickname}
          </p>

          <div className="flex flex-col items-start justify-start space-y-3">
            <Icon name="id-card" size="font" className="text-secondary">
              <Price
                className="text-wrap p-0 text-xs text-secondary sm:text-sm xs:text-[8px]"
                amount={service.price * currency.value}
                currency={currency.name}
              />
            </Icon>

            {service.isVideoCall && (
              <Icon name="clock" size="font" className="text-secondary">
                <p className="text-nowrap text-xs text-secondary sm:text-sm xs:text-[8px]">
                  {service.duration} min
                </p>
              </Icon>
            )}

            {service.isVideoCall ? (
              <Icon name="video" size="font" className="text-secondary">
                <p className="text-nowrap text-xs text-secondary sm:text-sm xs:text-[8px]">
                  Vídeollamada
                </p>
              </Icon>
            ) : (
              <Icon name="file-text" size="font" className="text-secondary">
                <p className="text-nowrap text-xs text-secondary sm:text-sm xs:text-[8px]">
                  Documento
                </p>
              </Icon>
            )}
          </div>
          <Button
            size={'sm'}
            className="mx-auto w-full max-w-full text-nowrap sm:w-full sm:min-w-full sm:max-w-full xs:w-[90%] xs:max-w-[90%]"
            onClick={() =>
              navigate(`/${service.professional.slug}/${service.slug}${query}`)
            }
          >
            <Icon name="calendar" size="xs" className="text-white">
              <p className="text-nowrap text-[10px] text-white sm:text-xs xs:text-[8px]">
                Haz tu reserva
              </p>
            </Icon>
          </Button>
        </div>
      </div>
      <Outlet />
    </>
  )
}
